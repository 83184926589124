exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-busca-js": () => import("./../../../src/pages/busca.js" /* webpackChunkName: "component---src-pages-busca-js" */),
  "component---src-pages-categoria-js": () => import("./../../../src/pages/categoria.js" /* webpackChunkName: "component---src-pages-categoria-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-fabricante-js": () => import("./../../../src/pages/fabricante.js" /* webpackChunkName: "component---src-pages-fabricante-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lista-js": () => import("./../../../src/pages/lista.js" /* webpackChunkName: "component---src-pages-lista-js" */),
  "component---src-pages-produto-js": () => import("./../../../src/pages/produto.js" /* webpackChunkName: "component---src-pages-produto-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-list-js": () => import("./../../../src/templates/list.js" /* webpackChunkName: "component---src-templates-list-js" */),
  "component---src-templates-manufacturer-js": () => import("./../../../src/templates/manufacturer.js" /* webpackChunkName: "component---src-templates-manufacturer-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

